exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-case-studies-abb-tsx": () => import("./../../../src/pages/case-studies/abb.tsx" /* webpackChunkName: "component---src-pages-case-studies-abb-tsx" */),
  "component---src-pages-case-studies-covid-model-tsx": () => import("./../../../src/pages/case-studies/covid-model.tsx" /* webpackChunkName: "component---src-pages-case-studies-covid-model-tsx" */),
  "component---src-pages-case-studies-ikem-tsx": () => import("./../../../src/pages/case-studies/ikem.tsx" /* webpackChunkName: "component---src-pages-case-studies-ikem-tsx" */),
  "component---src-pages-case-studies-lexxus-tsx": () => import("./../../../src/pages/case-studies/lexxus.tsx" /* webpackChunkName: "component---src-pages-case-studies-lexxus-tsx" */),
  "component---src-pages-case-studies-mrparkit-tsx": () => import("./../../../src/pages/case-studies/mrparkit.tsx" /* webpackChunkName: "component---src-pages-case-studies-mrparkit-tsx" */),
  "component---src-pages-case-studies-onsemi-tsx": () => import("./../../../src/pages/case-studies/onsemi.tsx" /* webpackChunkName: "component---src-pages-case-studies-onsemi-tsx" */),
  "component---src-pages-case-studies-teijin-tsx": () => import("./../../../src/pages/case-studies/teijin.tsx" /* webpackChunkName: "component---src-pages-case-studies-teijin-tsx" */),
  "component---src-pages-collaboration-tsx": () => import("./../../../src/pages/collaboration.tsx" /* webpackChunkName: "component---src-pages-collaboration-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-demand-tsx": () => import("./../../../src/pages/demand.tsx" /* webpackChunkName: "component---src-pages-demand-tsx" */),
  "component---src-pages-examples-index-tsx": () => import("./../../../src/pages/examples/index.tsx" /* webpackChunkName: "component---src-pages-examples-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-advanced-data-analytics-tsx": () => import("./../../../src/pages/services/advanced-data-analytics.tsx" /* webpackChunkName: "component---src-pages-services-advanced-data-analytics-tsx" */),
  "component---src-pages-services-business-intelligence-tsx": () => import("./../../../src/pages/services/business-intelligence.tsx" /* webpackChunkName: "component---src-pages-services-business-intelligence-tsx" */),
  "component---src-pages-services-data-science-tsx": () => import("./../../../src/pages/services/data-science.tsx" /* webpackChunkName: "component---src-pages-services-data-science-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-tutorials-tsx": () => import("./../../../src/pages/tutorials.tsx" /* webpackChunkName: "component---src-pages-tutorials-tsx" */)
}

